import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /*-
      <Title text="Projects" />
      - [3D Art with Cinema4D](https://www.lekoarts.de/art/3d?utm_source=minimal-blog&utm_medium=Theme)
      - [Landscape Photography](https://www.lekoarts.de/art/photography?utm_source=minimal-blog&utm_medium=Theme)
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      