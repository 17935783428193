import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hey fellow humans!
    </Text>
    <p>{`I'm Bruno. Recently I broke into `}<a parentName="p" {...{
        "href": "https://bugf.org/"
      }}>{`Bugs United Globally Foundation (BUGF) mailing server`}</a>{`
and I'm creating a book with some of the e-mails I found there. This blog describes the process of
writing the book.`}</p>
    <p>{`You can find the book on Leanpub: `}<a parentName="p" {...{
        "href": "https://leanpub.com/bug_emails/"
      }}>{`https://leanpub.com/bug_emails/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      